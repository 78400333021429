import * as THREE from "three";
import GLTFLoader from "three-gltf-loader"; //instalira se
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as dat from "dat.gui";

export const scene = new THREE.Scene();

export const gLTFLoader = new GLTFLoader();

export const dracoLoader = new DRACOLoader();

export const scene2 = new THREE.Scene();

export const clock = new THREE.Clock();

export const gui = new dat.GUI();
