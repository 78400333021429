import * as THREE from "three";
import {
  textureForFalseLight,
  textureForPeople,
} from "./helpers/textureLoader";

export const sphereGeometry = new THREE.PlaneGeometry(5, 5);

export const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

export const materialForFalseLight = new THREE.MeshStandardMaterial({
  map: textureForFalseLight,
  transparent: true,
  depthWrite: false,
  alphaTest: 0.08,
});

export const materialForPeople = new THREE.MeshStandardMaterial({
  map: textureForPeople,
  transparent: true,
  depthWrite: false,
  alphaTest: 0.08,
});

export const textForPopUpDE = [
  {
    id: "buttonModal1",
    heading: "Smart Growing Module",
    text: "<p>Das Smart Growing Module ist eine von der GreenState AG entwickelte Lösung.</p> <p>Das Modul hat eine Grösse von 15m2 und ist für den CBD-Anbau entwickelt.</p> <p>Das Modul beinhaltet eine IoT Hard- und Softwarelösung, ein Bewässerungssystem und eine Klimaanlage.</p>",
  },
  {
    id: "buttonModal2",
    heading: "Solar - Smart Growing Module",
    text: "<p>Smart Growing Module ist eine von der GreenState AG entwickelte Lösung.</p> <p>Das Modul hat eine Größe von 15m2 und ist für den CBD-Anbau entwickelt</p>. <p>Das Modul beinhaltet eine IoT Hard- und Softwarelösung, ein Bewässerungssystem und eine Klimaanlage.</p> <p>Der zusätzliche Aspekt dieses Moduls ist ein Solarpanel, das den Energieverbrauch um bis zu 40% reduziert.</p>",
  },
  {
    id: "buttonModal3",
    heading: "Vegi Smart Growing Module",
    text: "<p>Smart Growing Module ist eine von der GreenState AG entwickelte Lösung.</p> <p>Das Modul hat eine Größe von 15m2 und ist für den Gemüseanbau entwickelt worden.</p> <p>Das Modul beinhaltet eine IoT Hard- und Softwarelösung, ein Bewässerungssystem und eine Klimaanlage.</p> <p>Die Anpassung des Moduls hängt vom Anbauportfolio des Kunden ab.</p>",
  },
  {
    id: "buttonModal4",
    heading: "Solar-Vegi Smart Growing Module",
    text: "<p>Smart Growing Module ist eine von der GreenState AG entwickelte Lösung.</p> <p>Das Modul hat eine Grösse von 15m2 und ist für den Gemüseanbau entwickelt worden.</p> <p>Das Modul beinhaltet eine IoT Hard- und Softwarelösung, ein Bewässerungssystem und eine Klimaanlage.</p> <p>Die Anpassung des Moduls hängt vom Anbauportfolio des Kunden ab.</p> <p>Der zusätzliche Aspekt dieses Moduls ist ein Solarpanel, das den Energieverbrauch um bis zu 40% reduziert.</p>",
  },
  {
    id: "testModalID5",
    heading: "2x12 custom modul",
    text: "<p>Smart Growing Module is a solution developed by GreenState AG.</p> <p>The module has a size of 15m2 and is developed for vegetable growth.</p> <p>The module includes an IoT hardware and software solution, a watering system, and air-conditioning.</p> <p>The customization of the module depends on the clients growing portfolio.</p> <p>The additional aspect of this module is a solar panel which reduces energy consumption up to 40%.</p>",
  },
];

export const textForPopUpEN = [
  {
    id: "buttonModal1",
    heading: "Smart Growing Module",
    text: "<p>Smart Growing Module is a solution developed by GreenState AG.</p> <p>The module has a size of 15m2 and is developed for CBD growing.</p> <p>The module includes an IoT hardware and software solution, a watering system, and air-conditioning.</p>",
  },
  {
    id: "buttonModal2",
    heading: "Solar - Smart Growing Module",
    text: "<p>Smart Growing Module is a solution developed by GreenState AG.</p> <p>The module has a size of 15m2 and is developed for CBD growing.</p> <p>The module includes an IoT hardware and software solution, a watering system, and air-conditioning.</p> <p>The additional aspect of this module is a solar panel which reduces energy consumption up to 40%.</p>",
  },
  {
    id: "buttonModal3",
    heading: "Vegi Smart Growing Module",
    text: "<p>Smart Growing Module is a solution developed by GreenState AG.</p> <p>The module has a size of 15m2 and is developed for vegetable growth.</p> <p>The module includes an IoT hardware and software solution, a watering system, and air-conditioning.</p> <p>The customization of the module depends on the clients growing portfolio.</p>",
  },
  {
    id: "buttonModal4",
    heading: "Solar-Vegi Smart Growing Module ",
    text: "<p>Smart Growing Module is a solution developed by GreenState AG.</p> <p>The module has a size of 15m2 and is developed for vegetable growth.</p> <p>The module includes an IoT hardware and software solution, a watering system, and air-conditioning.</p> <p>The customization of the module depends on the clients growing portfolio.</p> <p>The additional aspect of this module is a solar panel which reduces energy consumption up to 40%.</p>",
  },
  {
    id: "testModalID5",
    heading: "2x12 custom modul",
    text: "<p>Smart Growing Module is a solution developed by GreenState AG.</p> <p>The module has a size of 15m2 and is developed for vegetable growth.</p> <p>The module includes an IoT hardware and software solution, a watering system, and air-conditioning.</p> <p>The customization of the module depends on the clients growing portfolio.</p> <p>The additional aspect of this module is a solar panel which reduces energy consumption up to 40%.</p>",
  },
];
