export const loaderBench = (scene, gLTFLoader) => {
  const matrixX = 7;
  const matrixY = 0;
  for (let y = 0; y <= matrixX; y++) {
    for (let x = 0; x <= matrixY; x++) {
      let klupa = "klupa.gltf";
      gLTFLoader.load(klupa, function (modelKlupe) {
        if (y % 5 == 0) {
          const rootKlupe1 = modelKlupe.scene;
          rootKlupe1.position
            .set(x - 0.74, -0.121, y - 2.45)
            .multiplyScalar(5.8);
          scene.add(rootKlupe1);
        }
      });
      gLTFLoader.load(klupa, function (modelKlupe) {
        if (y % 5 == 0) {
          const rootKlupe2 = modelKlupe.scene;
          rootKlupe2.position
            .set(x + 0.74, -0.121, y - 2.45)
            .multiplyScalar(5.8);
          rootKlupe2.rotation.y = 3.14159265;
          scene.add(rootKlupe2);
        }
      });
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const matriX = 0;
  const matriY = 7;
  for (let y = 0; y <= matriX; y++) {
    for (let x = 0; x <= matriY; x++) {
      let klupa = "klupa.gltf";
      gLTFLoader.load(klupa, function (modelKlupe) {
        if (x % 5 == 0) {
          const rootKlupe3 = modelKlupe.scene;
          rootKlupe3.position
            .set(x - 2.439, -0.121, y - 0.74)
            .multiplyScalar(5.8);
          rootKlupe3.rotation.y = -1.57079633;
          scene.add(rootKlupe3);
        }
      });
      gLTFLoader.load(klupa, function (modelKlupe) {
        if (x % 5 == 0) {
          const rootKlupe4 = modelKlupe.scene;
          rootKlupe4.position
            .set(x - 2.439, -0.121, y + 0.74)
            .multiplyScalar(5.8);
          rootKlupe4.rotation.y = 0;
          rootKlupe4.rotation.y = 1.57079633;
          scene.add(rootKlupe4);
        }
      });
    }
  }
};
