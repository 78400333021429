import * as THREE from "three";

export const lightForScene1 = (colorAmbient, intensityAmbient, scene2) => {
  const ambientLight = new THREE.AmbientLight(colorAmbient, intensityAmbient);

  scene2.add(ambientLight);
  let d = 12.25;
  const pointLightColor = 0x64480c;
  const pointLightIntensity = 0.6;
  const pointLight_Y = 0.065;
  const pointLight_X = 0;

  const pointLight1 = new THREE.PointLight(
    pointLightColor,
    pointLightIntensity
  );
  pointLight1.position.set(pointLight_X, pointLight_Y, -0.364);
  scene2.add(pointLight1);

  const pointLight2 = new THREE.PointLight(
    pointLightColor,
    pointLightIntensity
  );
  pointLight2.position.set(pointLight_X, pointLight_Y, 0.466);
  pointLight2.castShadow = true;
  pointLight2.castShadow = true;
  pointLight2.position.set(-0.169, 0.777, 5.295);
  pointLight2.castShadow = true;
  pointLight2.castShadow = true;
  pointLight2.shadow.mapSize = new THREE.Vector2(1024, 1024);
  pointLight2.shadow.camera.near = 0.1;
  pointLight2.shadow.camera.far = 1500;
  pointLight2.shadow.camera.left = d * -1;
  pointLight2.shadow.camera.right = d;
  pointLight2.shadow.camera.top = d;
  pointLight2.shadow.camera.bottom = d * -1;
  scene2.add(pointLight2);

  const pointLight3 = new THREE.PointLight(
    pointLightColor,
    pointLightIntensity
  );
  pointLight3.position.set(pointLight_X, pointLight_Y, 1.269);
  pointLight3.castShadow = true;
  pointLight3.castShadow = true;
  pointLight3.position.set(-0.169, 0.777, 5.295);
  pointLight3.castShadow = true;
  pointLight3.castShadow = true;
  pointLight3.shadow.mapSize = new THREE.Vector2(1024, 1024);
  pointLight3.shadow.camera.near = 0.1;
  pointLight3.shadow.camera.far = 1500;
  pointLight3.shadow.camera.left = d * -1;
  pointLight3.shadow.camera.right = d;
  pointLight3.shadow.camera.top = d;
  pointLight3.shadow.camera.bottom = d * -1;
  scene2.add(pointLight3);

  const pointLight4 = new THREE.PointLight(
    pointLightColor,
    pointLightIntensity
  );
  pointLight4.position.set(pointLight_X, pointLight_Y, 2.083);
  pointLight4.castShadow = true;
  pointLight4.position.set(-0.169, 0.777, 5.295);
  pointLight4.castShadow = true;
  pointLight4.castShadow = true;
  pointLight4.shadow.mapSize = new THREE.Vector2(1024, 1024);
  pointLight4.shadow.camera.near = 0.1;
  pointLight4.shadow.camera.far = 1500;
  pointLight4.shadow.camera.left = d * -1;
  pointLight4.shadow.camera.right = d;
  pointLight4.shadow.camera.top = d;
  pointLight4.shadow.camera.bottom = d * -1;
  scene2.add(pointLight4);

  const pointLight5 = new THREE.PointLight(pointLightColor, 1.5);
  pointLight5.position.set(-0.169, 0.777, 5.295);
  pointLight5.castShadow = true;
  pointLight5.castShadow = true;
  pointLight5.shadow.mapSize = new THREE.Vector2(1024, 1024);
  pointLight5.shadow.camera.near = 0.1;
  pointLight5.shadow.camera.far = 1500;
  pointLight5.shadow.camera.left = d * -1;
  pointLight5.shadow.camera.right = d;
  pointLight5.shadow.camera.top = d;
  pointLight5.shadow.camera.bottom = d * -1;
  scene2.add(pointLight5);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5, 0);
  directionalLight.position.set(5, 3.6, -2.1);
  directionalLight.castShadow = true;
  directionalLight.castShadow = true;
  directionalLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
  directionalLight.shadow.camera.near = 0.1;
  directionalLight.shadow.camera.far = 1500;
  directionalLight.shadow.camera.left = d * -1;
  directionalLight.shadow.camera.right = d;
  directionalLight.shadow.camera.top = d;
  directionalLight.shadow.camera.bottom = d * -1;
  scene2.add(directionalLight);
};

//for second scene
// export const lightForScene2 = (scene2) => {
//   const ambientLight = new THREE.AmbientLight(0xffffff, 1);
//   scene2.add(ambientLight);

//   const pointLightColor = 0xfffff;
//   const pointLightIntensity = 0.6;
//   const pointLight_Y = 0.065;
//   const pointLight_X = 0;

//   const pointLight1 = new THREE.PointLight(
//     pointLightColor,
//     pointLightIntensity
//   );
//   pointLight1.position.set(pointLight_X, pointLight_Y, -0.364);
//   scene2.add(pointLight1);

//   const pointLight2 = new THREE.PointLight(
//     pointLightColor,
//     pointLightIntensity
//   );
//   pointLight2.position.set(pointLight_X, pointLight_Y, 0.466);
//   scene2.add(pointLight2);

//   const pointLight3 = new THREE.PointLight(
//     pointLightColor,
//     pointLightIntensity
//   );
//   pointLight3.position.set(pointLight_X, pointLight_Y, 1.269);
//   scene2.add(pointLight3);

//   const pointLight4 = new THREE.PointLight(
//     pointLightColor,
//     pointLightIntensity
//   );
//   pointLight4.position.set(pointLight_X, pointLight_Y, 2.083);
//   scene2.add(pointLight4);

//   const pointLight5 = new THREE.PointLight(pointLightColor, 1.5);
//   pointLight5.position.set(-0.169, 0.777, 5.295);
//   scene2.add(pointLight5);

//   const directionalLight = new THREE.DirectionalLight(0xffffff, 1, 0);
//   directionalLight.position.set(5, 3.6, -2.1);
//   scene2.add(directionalLight);
// };
