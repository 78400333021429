import { renderer } from "./renderer";
import { sizes } from "../constants";
import { perspectiveCamera } from "./perspectiveCamera";

export const setMainPreferences = () => {
  perspectiveCamera.aspect = sizes.width / sizes.height;
  perspectiveCamera.fov = (sizes.width / sizes.height) * 4;
  perspectiveCamera.updateProjectionMatrix();
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2000));
  renderer.setSize(sizes.width, sizes.height);
  // textureLoader.load(arrowObjectTexture);
};
