import * as THREE from "three";

export const peopleAroundStreet = (lightObject, materialForFalseLight, planes3, scene) => {
    const matrixX = 4;
    const matrixY = 0;
    
    for (let y = 0; y <= matrixX; y++) {
      for (let x = 0; x <= matrixY; x++) {
        const plane1 = new THREE.Mesh(lightObject, materialForFalseLight);
         plane1.position.set(x - 12.439, 0.015, y - 7.061).multiplyScalar(6.43);

         const plane2 = new THREE.Mesh(lightObject, materialForFalseLight);
         plane2.position.set(x + 12.439, 0.015, y - 7.061).multiplyScalar(6.43);
          
            planes3.push(plane1, plane2);
            scene.add(plane1, plane2);
      }
    }
}