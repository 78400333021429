import * as THREE from "three";
import { lightObjectID, iconOfLightID } from "../helpers/getElementById";

export const switchForLight = (
  lightObject,
  materialForFalseLight,
  planes,
  scene
) => {
  const matrixX = 3;
  const matrixY = 2;
  let lightObjectVisible = false;

  for (let y = 0; y <= matrixX; y++) {
    for (let x = 0; x <= matrixY; x++) {
      const plane = new THREE.Mesh(lightObject, materialForFalseLight);
      plane.visible = lightObjectVisible;
      plane.position.set(x - 1.23, 1.25, y - 5.95).multiplyScalar(0.43);
      planes.push(plane);
      scene.add(plane);
    }
  }

  lightObjectID.addEventListener("click", function () {
    if (lightObjectVisible == false) {
      planes.forEach((e) => {
        console.log("suuuu p.m");
        lightObjectID.style.filter = "contrast(100%)";
        lightObjectID.style.background =
          "radial-gradient(circle,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.3) 30%,rgba(255, 255, 255, 0) 55%)";
        iconOfLightID.src =
          "https://res.cloudinary.com/dcl0dmpgl/image/upload/v1652269787/GreenState%20Media/Images/Icons%20for%20products%20page/light_on_a2pyuf.png";
        e.visible = true;
      });
    } else {
      planes.forEach((e) => {
        e.visible = false;
        lightObjectID.style.filter = "contrast(50%)";
        lightObjectID.style.background = "transparent";
        iconOfLightID.src =
          "https://res.cloudinary.com/dcl0dmpgl/image/upload/v1651231399/GreenState%20Media/Images/Icons%20for%20products%20page/light_sf3b5z.png";
      });
    }
    lightObjectVisible = !lightObjectVisible;
  });
};
