import * as THREE from "three";

export const centralPark = (gLTFLoader, scene) => {
  const matrixX = 7;
  const matrixY = 7;
  let klupa = "Zbun.gltf";

  for (let y = 0; y <= matrixX; y++) {
    for (let x = 0; x <= matrixY; x++) {
      if (y == 0 || y == 7 || x == 0 || x == 7) {
        gLTFLoader.load(klupa, function (modelKlupe) {
          const rootKlupe1 = modelKlupe.scene;
          rootKlupe1.scale.set(0.5, 0.5, 0.5);
          rootKlupe1.traverse((o) => {
            if (o.isMesh) {
              o.castShadow = true;
            }
          });
          rootKlupe1.position
            .set(x - 9.439, -0.3, y - 9.461)
            .multiplyScalar(2.43);
          scene.add(rootKlupe1);
        });
        gLTFLoader.load(klupa, function (modelKlupe) {
          const rootKlupe2 = modelKlupe.scene;
          rootKlupe2.scale.set(0.5, 0.5, 0.5);
          rootKlupe2.traverse((o) => {
            if (o.isMesh) {
              o.castShadow = true;
            }
          });
          rootKlupe2.position
            .set(x - 9.439, -0.3, y + 2.461)
            .multiplyScalar(2.43);
          scene.add(rootKlupe2);
        });
        gLTFLoader.load(klupa, function (modelKlupe) {
          const rootKlupe3 = modelKlupe.scene;
          rootKlupe3.scale.set(0.5, 0.5, 0.5);
          rootKlupe3.traverse((o) => {
            if (o.isMesh) {
              o.castShadow = true;
            }
          });
          rootKlupe3.position
            .set(x + 2.439, -0.3, y - 9.461)
            .multiplyScalar(2.43);
          scene.add(rootKlupe3);
        });
        gLTFLoader.load(klupa, function (modelKlupe) {
          const rootKlupe4 = modelKlupe.scene;
          rootKlupe4.scale.set(0.5, 0.5, 0.5);
          rootKlupe4.traverse((o) => {
            if (o.isMesh) {
              o.castShadow = true;
            }
          });
          rootKlupe4.position
            .set(x + 2.439, -0.3, y + 2.461)
            .multiplyScalar(2.43);
          scene.add(rootKlupe4);
        });
      }
      // else if(  x.valueOf(2) === 3 ){
      //   planes2.push(plane1, plane2, plane3, plane4);
      //   scene.add(plane1, plane2, plane3, plane4);
      // }
      //  console.log("jjjjjjjsdss",);
    }
  }
};
