import * as THREE from "three";
import { canvas } from "./querySelector";

export const renderer = new THREE.WebGLRenderer({
  //render
  canvas: canvas,
  alpha: true,
  antialias: true,
  toneMapping: true,
});
renderer.gammaOutput = true;
renderer.autoClearColor = false;
renderer.shadowMap.enabled = true;
