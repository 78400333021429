import { scene, gLTFLoader, dracoLoader } from "./threeVariable";
import { disabledMoveButtonOnClick5Modal } from "./disabledButtons";
import {
  containerInfo,
  sideBar,
  buttonModal1,
  headingModalID1,
  toggleButtonOfCameraControls,
  sideBar123,
  toggleArrowRotation,
  popUpID,
} from "./getElementById";
import {
  disabledModalPicture,
  handleStateTitleButtonNormalBoldEffect,
} from "./handleStateModalPictureOrTitle";
import { popUpOfModal } from "./popUpOfModal";
import { cSSGLTFLoader } from "./querySelector";

export function onLoadAndButtonModalClick(event, textOfPopUp) {
  let currentModule;

  let klupa = "scena_urbanPod.gltf";
  gLTFLoader.load(klupa, function (modelKlupe) {
    const rootKlupe1 = modelKlupe.scene;
    rootKlupe1.position.y = -0.8;
    rootKlupe1.traverse((o) => {
      if (o.isMesh) {
        o.receiveShadow = true;
      }
    });
    scene.add(rootKlupe1);
  });
  if (event == undefined) {
    //default object on page
    currentModule = "modulWebCBD.gltf";
    buttonModal1.disabled = true;
    buttonModal1.style.filter = "contrast(100%)";

    headingModalID1.style.fontWeight = "bold";

    const defaultInfoPopUp = textOfPopUp.filter((e) => e.id == "buttonModal1");

    defaultInfoPopUp.map((firstElement) => {
      const h3Tag = document.createElement("h3");
      const pTag = document.createElement("p");

      containerInfo.appendChild(h3Tag);
      containerInfo.appendChild(pTag);

      h3Tag.innerHTML = firstElement.heading;
      pTag.innerHTML = firstElement.text;
    });

    const headingsOfModals = textOfPopUp.filter((e) => e);
    const headingOfModal = sideBar.getElementsByClassName(
      "modalHomePage__navModal--button_heading"
    );
    // Set title of modals
    headingOfModal[0].innerHTML = headingsOfModals[0].heading;
    headingOfModal[1].innerHTML = headingsOfModals[1].heading;
    headingOfModal[2].innerHTML = headingsOfModals[2].heading;
    headingOfModal[3].innerHTML = headingsOfModals[3].heading;
    headingOfModal[4].innerHTML = headingsOfModals[4].heading;
  } else {
    currentModule = event.target.value;

    disabledModalPicture();

    //most buttonModal controls
    const { id } = event.target;
    const clickedButtonModal = document.getElementById(id);
    clickedButtonModal.disabled = true;
    clickedButtonModal.style.filter = "contrast(100%)";

    disabledMoveButtonOnClick5Modal(false);
    // sphere.rotation.z = 0;

    handleStateTitleButtonNormalBoldEffect(clickedButtonModal);

    //popUp of all object
    popUpOfModal(textOfPopUp, clickedButtonModal);

    //displayInfo.addEventListener("click", popup);
  }

  dracoLoader.setDecoderPath(
    "https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/"
  );
  gLTFLoader.setDRACOLoader(dracoLoader);

  gLTFLoader.load(
    currentModule,
    function (gltf) {
      // scene.remove(scene.children[9]);
      const root = gltf.scene;

      root.position.y = -0.8;
      if (currentModule == "2x12modulWeb.gltf") {
        root.scale.set(0.6, 0.6, 0.6);
        root.traverse((o) => {
          if (o.isMesh) {
            o.castShadow = true;
          }
        });
        root.position.y = -0.8;
        root.rotation.y = 3.14159265;
        toggleButtonOfCameraControls.style.display = false;
        toggleButtonOfCameraControls.style.opacity = "0%";

        sideBar123.style.transform = "scaleY(0)";
        toggleArrowRotation.style.transform = "scaleY(-1)";
        toggleButtonOfCameraControls.style.borderTopLeftRadius = "3.125rem";
        toggleButtonOfCameraControls.style.borderTopRightRadius = "3.125rem";
        toggleArrowRotation.style.opacity = "80%";
        popUpID.style.display = "none";
        disabledMoveButtonOnClick5Modal(true);
      } else if (currentModule !== "2x12modulWeb.gltf") {
        root.traverse((o) => {
          o.castShadow = true;
        });
        console.log("smaraaas");
        toggleButtonOfCameraControls.style.opacity = "100%";
        toggleButtonOfCameraControls.style.display = true;
        popUpID.style.display = "flex";
      }

      scene.add(root);

      cSSGLTFLoader.className += " hidden";
    },

    function (xhr) {
      //console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    function (error) {
      console.log(error);
    }
  );
}
