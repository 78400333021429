import "./css/style.min.css";
import * as THREE from "three";

import { TWEEN } from "three/examples/jsm/libs/tween.module.min";

import { scene, gLTFLoader, scene2, clock } from "./helpers/threeVariable";

import {
  textForPopUpDE,
  textForPopUpEN,
  materialForFalseLight,
  materialForPeople,
} from "./constants";
import { moveCameraOnButtonClick } from "./helpers/moveCameraOnButtonClick";
import { lightForScene1 } from "./helpers/light";
import { switchForLight } from "./helpers/switchForLight";
import {
  loadWindowResponse,
  resizeWindowResponse,
  windowWithSize,
} from "./helpers/loadResponse";
import {
  perspectiveCamera,
  handleResetCamera,
} from "./helpers/perspectiveCamera";
import { cSSGLTFLoader } from "./helpers/querySelector";
import { renderer } from "./helpers/renderer";
import { fetchMenu } from "./helpers/fetchMenu";
import {
  sideB,
  hamburgerM,
  showInfoPop,
  fingerSliderBreak,
  returnCSSElementOnStartPosition,
} from "./helpers/cSSEventOnClick";
import { onLoadAndButtonModalClick } from "./helpers/loaderModal";
import { sideBar } from "./helpers/getElementById";
import { centralPark } from "./helpers/mEshAround/centralPark";
import { loaderBench } from "./helpers/mEshAround/loaderBench";
import { peopleAroundStreet } from "./helpers/mEshAround/peopleAroundStreet";
import { lightInPark } from "./helpers//mEshAround/lightInPark";

document.addEventListener("contextmenu", (event) => event.preventDefault());

const language = window.navigator.userLanguage || window.navigator.language;

const de = `https://greenstate.ch/wp-json/wp-api-menus/v2/menus/4`;
const en = `https://greenstate.ch/wp-json/wp-api-menus/v2/menus/8`;

const segment_str = `https://www.greenstate.ch/en `; // return segment1/segment2/segment3/segment4

let textOfPopUp;
let isLanguageSupported;

if (language == "de") {
  isLanguageSupported = de;
  textOfPopUp = textForPopUpDE;
} else {
  isLanguageSupported = en;
  textOfPopUp = textForPopUpEN;
}
//locakStorage.setItem("userLanguage", jezik);
//const language = locakStorage.getItem("userLanguage");
fetchMenu(isLanguageSupported);

let mixer;
const onNavButtonModal = sideBar.querySelectorAll("button");

onNavButtonModal.forEach((e) => {
  // Open menu hover
  e.addEventListener("mouseover", function () {
    e.style.filter = "contrast(100%)";
  });
  e.addEventListener("mouseleave", function () {
    e.style.filter = "contrast(70%)";
  });

  e.addEventListener("click", (e) => {
    resetOnButton();
    onLoadAndButtonModalClick(e, textOfPopUp);
  });
});

const resetOnButton = () => {
  scene.clear();
  handleResetCamera();

  loaderBench(scene, gLTFLoader);
  centralPark(gLTFLoader, scene);
  peopleAroundStreet(peopleGeometry4, materialForPeople, planes3, scene);

  lightInPark(scene, gLTFLoader);

  cSSGLTFLoader.classList.remove("hidden"); // class "loader hidden"

  switchForLight(lightObject, materialForFalseLight, planes, scene);
};

cSSGLTFLoader.className += " show";

const colorAmbient = 0xeee4ce;
const intensityAmbient = 1;

lightForScene1(colorAmbient, intensityAmbient, scene2);

//MENJANJE SCENA NA button CLICK ---------------------------------------------------------------------------------
// const scena1OnButton = document.getElementById("scena1ID");
// const scena2OnButton = document.getElementById("scena2ID");

// scena1OnButton.addEventListener("click", sceneOnLoadAndOnButton, false);
// scena2OnButton.addEventListener("click", sceneOnLoadAndOnButton, false);

// scena1OnButton.addEventListener("click", function () {
//   scene2.clear();
//   lightForScene1(colorAmbient, intensityAmbient,scene2);
//   handleResetCamera();
// });

// scena2OnButton.addEventListener("click", function () {
//   scene2.clear();
//   lightForScene2(scene2);
//   handleResetCamera();
// });

sceneOnLoadAndOnButton();
function sceneOnLoadAndOnButton(e) {
  let currentScene;

  if (e == undefined) {
    currentScene = "scena_urban.gltf";
    //lightForScene1(scene2);
    // scena1OnButton.disabled = true;
  } else {
    lightForScene1(colorAmbient, intensityAmbient, scene2);
    currentScene = e.target.value;
    // scena1OnButton.disabled = false;
    // scena2OnButton.disabled = false;

    // scena1OnButton.style.filter = "contrast(70%)";
    // scena2OnButton.style.filter = "contrast(70%)";

    //most buttonModal controls
    const { id } = e.target;
    const clickedButtonScene = document.getElementById(id);
    clickedButtonScene.disabled = true;
    clickedButtonScene.style.filter = "contrast(100%)";
  }
  //handle onButton
  //loader for scene
  // loaderForScene(gLTFLoader, currentScene, scene2, mixer, scene);
  gLTFLoader.load(currentScene, function (currentScene) {
    const rootScene = currentScene.scene;
    rootScene.position.y = -0.75;

    scene2.add(rootScene);
    //animation for scene
    if (currentScene.animations[0] !== undefined) {
      mixer = new THREE.AnimationMixer(rootScene);
      const action = mixer.clipAction(currentScene.animations[0]);
      action.play();
      action.timeScale = 0.008;
    }
    scene2.add(scene);
  });
  // mixer = loaderForScene(gLTFLoader, currentScene, scene2, mixer, scene);
}

loaderBench(scene, gLTFLoader);
// window.addEventListener("click", function(){
//   let r = perspectiveCamera.rotation.y -0.5;
//   tweenAnimationForRotation(perspectiveCamera.rotation.x, r, perspectiveCamera.rotation.z, perspectiveCamera, "rotation", 2000)
// })

//loader for GLTF File .........................................................................................
let pending = false;
let e = undefined;
onLoadAndButtonModalClick(e, textOfPopUp);
//..............................................................................................................

//reset perspectiveCamera
handleResetCamera();
//............................................................................................

//CSS class EventListeners all
sideB();
hamburgerM();
showInfoPop();
fingerSliderBreak();
returnCSSElementOnStartPosition();

// On load window / Resize window
resizeWindowResponse();
loadWindowResponse();
windowWithSize();

//orbit controls

//............................................................................................
//Set position camera
let timeForReset = 600;
let timeForMoveCamera = 1500;
moveCameraOnButtonClick(perspectiveCamera, timeForReset, timeForMoveCamera);

//............................................................................................
const step = 3;
//render
const lightObject = new THREE.PlaneGeometry(0.55, 1.4);

let planes = [];
switchForLight(lightObject, materialForFalseLight, planes, scene);

//scena i  animaci mixer

// create an AudioListener and add it to the camera
// const listener = new THREE.AudioListener();
// camera.add( listener );

// // create a global audio source
// const sound = new THREE.Audio( listener );

// // load a sound and set it as the Audio object's buffer
// const audioLoader = new THREE.AudioLoader();
// audioLoader.load( 'sounds/ambient.ogg', function( buffer ) {
//   sound.setBuffer( buffer );
//   sound.setLoop( true );
//   sound.setVolume( 0.5 );
//   sound.play();
// });

// False light
// {
//   const loader = new THREE.CubeTextureLoader();
//   const texture = loader.load([
//     'https://r105.threejsfundamentals.org/threejs/resources/images/cubemaps/computer-history-museum/pos-x.jpg',
//     'https://r105.threejsfundamentals.org/threejs/resources/images/cubemaps/computer-history-museum/neg-x.jpg',
//     'https://r105.threejsfundamentals.org/threejs/resources/images/cubemaps/computer-history-museum/pos-y.jpg',
//     'https://r105.threejsfundamentals.org/threejs/resources/images/cubemaps/computer-history-museum/neg-y.jpg',
//     'https://r105.threejsfundamentals.org/threejs/resources/images/cubemaps/computer-history-museum/pos-z.jpg',
//     'https://r105.threejsfundamentals.org/threejs/resources/images/cubemaps/computer-history-museum/neg-z.jpg',
//   ]);
//  scene2.background = texture;
// }

const peopleGeometry = new THREE.PlaneGeometry(0.55, 0.8);
let planes2 = [];
centralPark(gLTFLoader, scene);

const peopleGeometry4 = new THREE.PlaneGeometry(3.55, 1.5);
let planes3 = [];
peopleAroundStreet(peopleGeometry4, materialForPeople, planes3, scene);

lightInPark(scene, gLTFLoader);

let directionCamera = new THREE.Vector3();
let copyDirectionCamera = new THREE.Vector3();

const tick = () => {
  const delta = clock.getDelta();

  perspectiveCamera.getWorldDirection(directionCamera);
  directionCamera.negate().setY(0).normalize();

  planes.forEach((p) => {
    p.lookAt(copyDirectionCamera.copy(directionCamera).add(p.position));
  });
  planes3.forEach((p) => {
    p.lookAt(copyDirectionCamera.copy(directionCamera).add(p.position));
  });

  if (mixer !== undefined) {
    mixer.update(delta * 20);
  }

  TWEEN.update();
  renderer.render(scene2, perspectiveCamera);
  window.requestAnimationFrame(tick);
};
tick();
