import { setMainPreferences } from "./setMainPreferences";
import {
  removeNavClass,
  headerHandle,
  controlsObjectHandle,
  containerInfo,
  sideBar,
  openMenu,
  showInfoPopUp,
} from "./getElementById";
import { sizes } from "../constants";
import { perspectiveCamera } from "./perspectiveCamera";
import { renderer } from "./renderer";

export const loadWindowResponse = () => {
  window.addEventListener("load", function () {
    sizes.width = window.innerWidth;

    if (window.innerWidth > 1025) {
      removeNavClass.classList.add("container");
      removeNavClass.classList.remove("container-fluid");
    } else if (window.innerWidth < 1025) {
      removeNavClass.classList.remove("container");
      removeNavClass.classList.add("container-fluid");
    }
    if (sizes.height <= 850) {
      if (sizes.width < 1660 && sizes.width >= 1350) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.2;
      } else if (sizes.width < 1350 && sizes.width >= 1200) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.25;
      } else if (sizes.width < 1200 && sizes.width >= 1100) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.15;
      } else if (sizes.width < 1100 && sizes.width >= 1000) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.12;
      } else if (sizes.width < 1000 && sizes.width >= 830) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      } else if (sizes.width < 830 && sizes.width >= 720) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.06;
      } else if (sizes.width < 720 && sizes.width >= 550) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.043;
      } else if (sizes.width < 550 && sizes.width >= 460) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.035;
      } else if (sizes.width < 460 && sizes.width >= 420) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.025;
      } else if (sizes.width < 420 && sizes.width >= 380) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.022;
      } else if (sizes.width < 380 && sizes.width >= 330) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.02;
      } else if (sizes.width < 330 && sizes.width > 300) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.023;
      } else if (sizes.width < 300) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.0122;
      } else {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.25;
      }
    } else {
      if (sizes.width > 2200) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.2;
      } else if (sizes.width > 1660 && 2200 <= sizes.width) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.3;
      } else if (sizes.width < 1660 && sizes.width >= 1400) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.18;
      } else if (sizes.width < 1400 && sizes.width >= 1350) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      } else if (sizes.width < 1350 && sizes.width >= 1200) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.15;
      } else if (sizes.width < 1200 && sizes.width >= 1100) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      } else if (sizes.width < 1100 && sizes.width >= 1000) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.04;
      } else if (sizes.width < 1000 && sizes.width >= 830) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.038;
      } else if (sizes.width < 830 && sizes.width >= 720) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.0345;
      } else if (sizes.width < 720 && sizes.width >= 550) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.03;
      } else if (sizes.width < 550 && sizes.width >= 460) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.025;
      } else if (sizes.width < 420 && sizes.width >= 360) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.015;
      } else if (sizes.width < 360) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.01;
      } else {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.25;
      }
    }
    setMainPreferences();
  });
};

export const resizeWindowResponse = () => {
  window.addEventListener("resize", () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    if (window.innerWidth > 1025) {
      removeNavClass.classList.add("container");
      removeNavClass.classList.remove("container-fluid");
    } else if (window.innerWidth < 1025) {
      removeNavClass.classList.remove("container");
      removeNavClass.classList.add("container-fluid");
    }
    if (sizes.height <= 350) {
      sizes.width = window.innerWidth;
      perspectiveCamera.zoom = 0.4;
      sideBar.style.display = "none";
      openMenu.style.display = "none";
      headerHandle.style.display = "none";
      showInfoPopUp.style.display = "none";
      controlsObjectHandle.style.display = "none";
    } else if (sizes.height <= 850) {
      sideBar.style.display = "flex";
      openMenu.style.display = "flex";
      headerHandle.style.display = "flex";
      showInfoPopUp.style.display = "flex";
      controlsObjectHandle.style.display = "flex";
      if (sizes.width < 1660 && sizes.width >= 1545) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.17;
      } else if (sizes.width < 1545 && sizes.width >= 1400) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.14;
      } else if (sizes.width < 1400 && sizes.width >= 1350) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.13;
      } else if (sizes.width < 1350 && sizes.width >= 1200) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.25;
      } else if (sizes.width < 1200 && sizes.width >= 1100) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.15;
      } else if (sizes.width < 1100 && sizes.width >= 1000) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.11;
      } else if (sizes.width < 1000 && sizes.width >= 830) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      } else if (sizes.width < 830 && sizes.width >= 720) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.07;
      } else if (sizes.width < 720 && sizes.width >= 590) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.05;
      } else if (sizes.width < 590 && sizes.width >= 460) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.034;
      } else if (sizes.width < 460 && sizes.width >= 420) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.025;
      } else if (sizes.width < 420 && sizes.width >= 360) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.022;
      } else if (sizes.width < 360 && sizes.width >= 300) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.025;
      } else if (sizes.width < 300) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.013;
      } else {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.25;
      }
    } else {
      if (sizes.width > 1660) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.21;
      } else if (sizes.width < 1660 && sizes.width >= 1400) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      } else if (sizes.width < 1400 && sizes.width >= 1350) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      } else if (sizes.width < 1350 && sizes.width >= 1200) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      } else if (sizes.width < 1200 && sizes.width >= 1100) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      } else if (sizes.width < 1100 && sizes.width >= 1000) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.04;
      } else if (sizes.width < 1000 && sizes.width >= 830) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.03;
      } else if (sizes.width < 830 && sizes.width >= 720) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.035;
      } else if (sizes.width < 720 && sizes.width >= 550) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.0295;
      } else if (sizes.width < 550 && sizes.width >= 460) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.025;
      } else if (sizes.width < 460 && sizes.width >= 420) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.02;
      } else if (sizes.width < 420 && sizes.width >= 360) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.015;
      } else if (sizes.width < 360) {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.03;
      } else {
        sizes.width = window.innerWidth;
        perspectiveCamera.zoom = 0.1;
      }
    }
    setMainPreferences();
  });
};

export const windowWithSize = () => {
  if (sizes.width >= 3800) {
    renderer.setPixelRatio((window.devicePixelRatio = 1));
  } else if (sizes.width >= 2600) {
    renderer.setPixelRatio((window.devicePixelRatio = 1.2));
  } else {
    renderer.setPixelRatio((window.devicePixelRatio = 1.5));
  }
};
