
export const fetchMenu = (isLanguageSupported) => {
    
    fetch(isLanguageSupported)
      .then((response) => response.json())
      .then((html) => {
        const items = html.items;
        const navB = document.getElementById("navBarUl");
        items.map((item) => {
          const liLista = document.createElement("li");
          const aTag = document.createElement("a");

          liLista.innerHTML;
          aTag.innerHTML = item.title;
          aTag.setAttribute("href", item.url);

          const lista = navB.appendChild(liLista);
          lista.appendChild(aTag);
    });
  });
}