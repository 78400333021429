import * as THREE from "three";

export const textureLoader = new THREE.TextureLoader();

const growthLight = "growthLight.png";
export const textureForFalseLight = textureLoader.load(growthLight);
textureForFalseLight.clone();
textureForFalseLight.dispose();
textureForFalseLight.needsUpdate = false;

const people = "people.png";
export const textureForPeople = textureLoader.load(people);
textureForPeople.clone();
textureForPeople.dispose();
textureForPeople.needsUpdate = false;
