import {
  buttonModal1,
  buttonModal2,
  buttonModal3,
  buttonModal4,
  headingModalID1,
  headingModalID2,
  headingModalID3,
  headingModalID4,
  testModalID5,
} from "./getElementById";

export const disabledModalPicture = () => {
  buttonModal1.disabled = false;
  buttonModal2.disabled = false;
  buttonModal3.disabled = false;
  buttonModal4.disabled = false;
  testModalID5.disabled = false;
  buttonModal1.style.filter = "contrast(70%)";
  buttonModal2.style.filter = "contrast(70%)";
  buttonModal3.style.filter = "contrast(70%)";
  buttonModal4.style.filter = "contrast(70%)";
  testModalID5.style.filter = "contrast(70%)";
};

export const handleStateTitleButtonNormalBoldEffect = (clickedButtonModal) => {
  headingModalID1.style.fontWeight = "normal";
  headingModalID2.style.fontWeight = "normal";
  headingModalID3.style.fontWeight = "normal";
  headingModalID4.style.fontWeight = "normal";
  testModalID5.style.fontWeight = "normal";

  if (clickedButtonModal.id == "buttonModal1") {
    headingModalID1.style.fontWeight = "bold";
  } else if (clickedButtonModal.id == "buttonModal2") {
    headingModalID2.style.fontWeight = "bold";
  } else if (clickedButtonModal.id == "buttonModal3") {
    headingModalID3.style.fontWeight = "bold";
  } else if (clickedButtonModal.id == "buttonModal4") {
    headingModalID4.style.fontWeight = "bold";
  } else if (clickedButtonModal.id == "testModalID5") {
    testModalID5.style.fontWeight = "bold";
  }
};
