import {
  sideBar,
  sideBar123,
  toggleButtonOfCameraControls,
  openMenu,
  showInfoPopUp,
  hamburgerMenu,
  fingerSlider,
  toggleArrowRotation,
} from "./getElementById";
import { canvas } from "./querySelector";
import { gLTFLoader } from "./threeVariable";

export const sideB = () => {
  sideBar.classList.toggle("active");
  openMenu.classList.remove("activeOpen");
  hamburgerMenu.checked = false;
};
sideBar.addEventListener("click", () => sideB());

export const hamburgerM = () => {
  openMenu.classList.toggle("activeOpen");
  sideBar.classList.remove("active");
  popup_info.classList.remove("isActivePopUp");
  // toggleButtonOfCameraControls.style.display = "flex";
  sideBar123.style.transform = "scaleY(0)";
  toggleArrowRotation.style.transform = "scaleY(-1)";
  toggleArrowRotation.style.opacity = "80%";
  setTimeout(() => {
    toggleButtonOfCameraControls.style.borderTopLeftRadius = "3.125rem";
    toggleButtonOfCameraControls.style.borderTopRightRadius = "3.125rem";
    sime = !sime;
  }, 200);
  toggleButtonOfCameraControls.style.display = "flex";
  sideBar123.style.display = "flex";
  popUp = false;
};
hamburgerMenu.addEventListener("click", () => hamburgerM());
//ponavlja se rei posle
export const showInfoPop = () => {
  popup_info.classList.toggle("isActivePopUp");
  openMenu.classList.remove("activeOpen");
  hamburgerMenu.checked = false;
};
showInfoPopUp.addEventListener("click", () => showInfoPop());

export const fingerSliderBreak = () => {
  fingerSlider.style.display = "none";
};
window.addEventListener("pointerdown", () => fingerSliderBreak());

let popUp = false;

export const returnCSSElementOnStartPosition = (modal) => {
  openMenu.classList.remove("activeOpen");
  popup_info.classList.remove("isActivePopUp");
  sideBar.classList.remove("active");
  hamburgerMenu.checked = false;
  if (modal !== "2x12modulWeb.gltf") {
    toggleButtonOfCameraControls.style.display = "flex";
    sideBar123.style.display = "flex";
    popUp = false;
  }
};
canvas.addEventListener("pointerdown", () => returnCSSElementOnStartPosition());

let sime = true;
toggleButtonOfCameraControls.addEventListener("click", function () {
  hamburgerMenu.checked = false;
  openMenu.classList.remove("activeOpen");
  if (sime === false) {
    sideBar123.style.transform = "scaleY(1)";
    toggleButtonOfCameraControls.style.borderTopLeftRadius = "0";
    toggleButtonOfCameraControls.style.borderTopRightRadius = "0";
    toggleArrowRotation.style.transform = "scaleY(1)";
    toggleArrowRotation.style.opacity = "100%";
    sime = !sime;
  } else {
    sideBar123.style.transform = "scaleY(0)";
    toggleArrowRotation.style.transform = "scaleY(-1)";
    toggleArrowRotation.style.opacity = "80%";
    setTimeout(() => {
      toggleButtonOfCameraControls.style.borderTopLeftRadius = "3.125rem";
      toggleButtonOfCameraControls.style.borderTopRightRadius = "3.125rem";
      sime = !sime;
    }, 200);
  }
});

showInfoPopUp.addEventListener("click", function () {
  if (popUp == false) {
    toggleButtonOfCameraControls.style.display = "none";
    sideBar123.style.display = "none";
    popUp = !popUp;
  } else {
    toggleButtonOfCameraControls.style.display = "flex";
    sideBar123.style.display = "flex";
    popUp = !popUp;
  }
});
