import * as THREE from "three";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min";
import { disabledMoveButtonOnClick5Modal } from "./disabledButtons";
import { sideBar123 } from "./getElementById";

export const moveCameraOnButtonClick = (
  perspectiveCamera,
  timeForReset,
  timeForGo
) => {
  const positionTween = new TWEEN.Tween(perspectiveCamera.position).easing(
    TWEEN.Easing.Quadratic.InOut
  );
  const rotationTween = new TWEEN.Tween(perspectiveCamera.rotation).easing(
    TWEEN.Easing.Quadratic.InOut
  );

  const buttonCameraSettings = {
    cameraMoveIDTV: {
      position: { x: -0.95, y: 0.888, z: 2.1 },
      rotation: { x: 0, y: -1.56, z: 0 },
    },
    cameraMoveIDPlants: {
      position: { x: -1.138, y: 0.893, z: 0.598 },
      rotation: { x: -0.36, y: -0.4, z: 0 },
    },
    cameraMoveIDNewspaper: {
      position: { x: -0.68, y: 0.686, z: 1.986 },
      rotation: { x: -1.7, y: 0.2, z: 1.5 },
    },
    cameraMoveIDPosition: {
      position: {
        x: -9.124211282466753,
        y: 2.10558721903079,
        z: 3.509312031717982,
      },
      rotation: {
        x: -0.5404195002705843,
        y: -1.1491615624045923,
        z: -0.5008853892313582,
      },
      resetCameraPosition: "resetCameraOnStartedPosition",
    },
    cameraMoveIDEngine: {
      position: { x: -0.756, y: -0.245, z: -0.2 },
      rotation: { x: 0, y: -1.5, z: 0 },
    },
  };

  const button1 = sideBar123.querySelectorAll("button");

  button1.forEach((e) => {
    // Open menu hover
    e.addEventListener("click", function (ev) {
      if (
        ev.target.id === "lightObjectID" ||
        ev.target.id === "toggleButtonID"
      ) {
      } else {
        const buttonId = ev.target.id;
        const cameraSettings = buttonCameraSettings[buttonId];
        updateCameraTweens(cameraSettings);

        disabledMoveButtonOnClick5Modal(false, buttonId);
      }
    });
  });

  function updateCameraTweens(params) {
    if (params.resetCameraPosition == "resetCameraOnStartedPosition") {
      if (params.position) {
        positionTween.stop();
        positionTween.to(params.position, timeForReset).start();
      }

      if (params.rotation) {
        rotationTween.stop();
        rotationTween.to(params.rotation, timeForReset).start();
      }
    } else {
      if (params.position) {
        positionTween.stop();
        positionTween.to(params.position, timeForGo).start();
      }

      if (params.rotation) {
        rotationTween.stop();
        rotationTween.to(params.rotation, timeForGo).start();
      }
    }
  }

  const quatTween = new TWEEN.Tween(perspectiveCamera.quaternion);
  const toQuaternion = new THREE.Quaternion(); //ispravlja animaciju, pomaze pri smutovanju, on ce ici kroz sve ose
  const toEuler = new THREE.Euler(); //imam ova tri parametra xyz smatram da je ovo idealana ruta izmedju njis

  // in updateCameraTweens()
  toEuler.set(
    perspectiveCamera.rotation.x,
    perspectiveCamera.rotation.y,
    perspectiveCamera.rotation.z
  );
  toQuaternion.setFromEuler(toEuler);
  quatTween.to(toQuaternion, 1000).start();
};
