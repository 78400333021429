export const lightInPark = (scene, gLTFLoader) => {
  const matrixX = 7;
  const matrixY = 0;
  for (let y = 0; y <= matrixX; y++) {
    for (let x = 0; x <= matrixY; x++) {
      let klupa = "banderaOstrvo.gltf";
      gLTFLoader.load(klupa, function (modelKlupe) {
        if (y % 7 == 0) {
          const rootKlupe1 = modelKlupe.scene;
          rootKlupe1.position.set(x - 0, -0.121, y - 3.489).multiplyScalar(5.8);
          rootKlupe1.rotation.y = -1.57079633;
          rootKlupe1.traverse((o) => {
            if (o.isMesh) {
              o.castShadow = true;
            }
          });
          scene.add(rootKlupe1);
        }
      });
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const matriX = 0;
  const matriY = 7;
  for (let y = 0; y <= matriX; y++) {
    for (let x = 0; x <= matriY; x++) {
      let klupa = "banderaOstrvo.gltf";
      gLTFLoader.load(klupa, function (modelKlupe) {
        if (x % 7 == 0) {
          const rootKlupe3 = modelKlupe.scene;
          rootKlupe3.position.set(x - 3.489, -0.121, y - 0).multiplyScalar(5.8);
          rootKlupe3.rotation.y = 3.14159265;
          rootKlupe3.traverse((o) => {
            if (o.isMesh) {
              o.castShadow = true;
            }
          });
          scene.add(rootKlupe3);
        }
      });
    }
  }
};
