export const removeNavClass = document.getElementById("container"); //css
export const headerHandle = document.getElementById("headerID");
export const controlsObjectHandle = document.getElementById("controlsObjectID"); //????
export const containerInfo = document.getElementById("popup_info");
export const sideBar = document.getElementById("sideBar");
export const openMenu = document.getElementById("menu_mobile");
export const showInfoPopUp = document.getElementById("show_info");
export const hamburgerMenu = document.getElementById("menu__toggle");
export const fingerSlider = document.getElementById("dragToRotate");
export const displayInfo = document.getElementById("show_info");
export const popUpID = document.getElementById("popUpID");

export const buttonModal1 = document.getElementById("buttonModal1");
export const buttonModal2 = document.getElementById("buttonModal2");
export const buttonModal3 = document.getElementById("buttonModal3");
export const buttonModal4 = document.getElementById("buttonModal4");

export const headingModalID1 = document.getElementById("headingModalID1");
export const headingModalID2 = document.getElementById("headingModalID2");
export const headingModalID3 = document.getElementById("headingModalID3");
export const headingModalID4 = document.getElementById("headingModalID4");
export const testModalID5 = document.getElementById("testModalID5");

export const sideBar123 = document.getElementById("buttonCameraControlID");
export const cameraMoveOnTV = document.getElementById("cameraMoveIDTV");
export const cameraMoveOnPlants = document.getElementById("cameraMoveIDPlants");
export const cameraMoveOnNewspaper = document.getElementById(
  "cameraMoveIDNewspaper"
);
export const lightObjectID = document.getElementById("lightObjectID");
export const cameraResetOnStartPosition = document.getElementById(
  "cameraMoveIDPosition"
);
export const cameraMoveOnEngine = document.getElementById("cameraMoveIDEngine");
export const toggleButtonOfCameraControls =
  document.getElementById("toggleButtonID");
export const toggleArrowRotation = document.getElementById("toggleControls");

export const iconOfLightID = document.getElementById("iconOfLightID");
