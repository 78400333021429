import {containerInfo, displayInfo,} from "./getElementById"

export const popUpOfModal = ( textOfPopUp, clickedButtonModal) => {
    const paragraphOfPopUp = containerInfo.querySelector("p");
    containerInfo.removeChild(paragraphOfPopUp);

    const paragraphOfPopUp1 = containerInfo.querySelector("h3");
    containerInfo.removeChild(paragraphOfPopUp1);

    const currentText = textOfPopUp.filter((e) => e.id == clickedButtonModal.id);

    let popup = currentText.map((e) => {
      if (displayInfo.classList.contains("modalHomePage__popup--button--one")) {
        const h3Tag = document.createElement("h3");
        const pTag = document.createElement("p");

        containerInfo.appendChild(h3Tag);
        containerInfo.appendChild(pTag);

        pTag.innerHTML = e.text;
        h3Tag.innerHTML = e.heading;
      }
    });
}