import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { renderer } from "./renderer";
import { perspectiveCamera } from "./perspectiveCamera";
import * as THREE from "three";

export const orbitControls = () => {
  const controlsOrbitControls = new OrbitControls(
    perspectiveCamera,
    renderer.domElement
  );

  controlsOrbitControls.maxDistance = 70;
  controlsOrbitControls.minDistance = 13;
  controlsOrbitControls.enableRotate = true;
  controlsOrbitControls.maxPolarAngle = 1.57;
  controlsOrbitControls.maxAzimuthAngle = -Math.PI / 5;
  controlsOrbitControls.enablePan = false;
  controlsOrbitControls.center;
  controlsOrbitControls.screenSpacePanning = false;
  controlsOrbitControls.enableDamping = false;
  controlsOrbitControls.rotateSpeed = 0.25;
  controlsOrbitControls.zoomSpeed = 0.5;
  controlsOrbitControls.position0 = true;
  controlsOrbitControls.update();

  //   controlsOrbitControls.keys = {
  //     LEFT: THREE.MOUSE,
  //     MIDDLE: THREE.MOUSE.MIDDLE,
  //     RIGHT: THREE.MOUSE.LEFT
  // }
};
