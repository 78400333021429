import * as THREE from "three";
import {sizes} from "../constants"
import {orbitControls} from "./orbitControls"

export const perspectiveCamera = new THREE.PerspectiveCamera(
    0,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  
  perspectiveCamera.zoom = 0.15;
  perspectiveCamera.position.set(-13, 3, 5);
  perspectiveCamera.aspect = sizes.width / sizes.height;
  perspectiveCamera.fov = (sizes.width / sizes.height) * 4;
  perspectiveCamera.updateProjectionMatrix();

  export const handleResetCamera = () => {
    perspectiveCamera.position.set(-13, 3, 5);
    orbitControls();
  };