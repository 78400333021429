import {
  cameraMoveOnTV,
  toggleButtonOfCameraControls,
  cameraMoveOnPlants,
  cameraMoveOnNewspaper,
  cameraResetOnStartPosition,
  cameraMoveOnEngine,
  lightObjectID,
  sideBar123,
} from "./getElementById";
import { canvas } from "./querySelector";

export const disabledMoveButtonOnClick5Modal = (event, e) => {
  cameraMoveOnTV.disabled = event;
  cameraMoveOnPlants.disabled = event;
  cameraMoveOnNewspaper.disabled = event;
  cameraResetOnStartPosition.disabled = event;
  cameraMoveOnEngine.disabled = event;

  cameraMoveOnTV.style.filter = "contrast(50%)";
  cameraMoveOnPlants.style.filter = "contrast(50%)";
  cameraMoveOnNewspaper.style.filter = "contrast(50%)";
  cameraResetOnStartPosition.style.filter = "contrast(50%)";
  cameraMoveOnEngine.style.filter = "contrast(50%)";
  lightObjectID.style.filter = "contrast(50%)";

  cameraMoveOnTV.style.background = "transparent ";
  cameraMoveOnPlants.style.background = "transparent";
  cameraMoveOnNewspaper.style.background = "transparent";
  cameraMoveOnEngine.style.background = "transparent ";
  cameraResetOnStartPosition.style.background = "transparent ";

  //ako opet pozovem funkciju "disabledMoveButtonOnClick5Modal()" dolazi do baga posle 14X pomeranja objekta, iz tog razloga sam samo kopirao
  canvas.addEventListener("click", function () {
    cameraMoveOnTV.disabled = event;
    cameraMoveOnPlants.disabled = event;
    cameraMoveOnNewspaper.disabled = event;
    cameraResetOnStartPosition.disabled = event;
    cameraMoveOnEngine.disabled = event;

    cameraMoveOnTV.style.filter = "contrast(50%)";
    cameraMoveOnPlants.style.filter = "contrast(50%)";
    cameraMoveOnNewspaper.style.filter = "contrast(50%)";
    cameraResetOnStartPosition.style.filter = "contrast(50%)";

    toggleButtonOfCameraControls.filter = "contrast(50%)";
  });

  if (e !== undefined) {
    const clickedButton = document.getElementById(e);
    if (clickedButton.id == "cameraMoveIDPosition") {
      // clickedButton.style.filter = "contrast(100%)";
      // clickedButton.style.background =
      //   "radial-gradient(circle,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.3) 30%,rgba(255, 255, 255, 0) 55%) ";
    } else {
      clickedButton.style.filter = "contrast(100%)";
      clickedButton.style.background =
        "radial-gradient(circle,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.3) 30%,rgba(255, 255, 255, 0) 55%)";
      clickedButton.disabled = true;
      cameraResetOnStartPosition.style.filter = "contrast(100%)";
    }
  }
  //haver on icons of controls
  cameraMoveOnTV.addEventListener("mouseover", function () {
    cameraMoveOnTV.style.background =
      "radial-gradient(circle,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.3) 30%,rgba(255, 255, 255, 0) 55%)";
    cameraMoveOnTV.style.filter = "contrast(100%)";
  });
  cameraMoveOnTV.addEventListener("mouseleave", function () {
    cameraMoveOnTV.style.background = "transparent";
  });
  cameraMoveOnPlants.addEventListener("mouseover", function () {
    cameraMoveOnPlants.style.background =
      "radial-gradient(circle,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.3) 30%,rgba(255, 255, 255, 0) 55%)";
    cameraMoveOnPlants.style.filter = "contrast(100%)";
  });
  cameraMoveOnPlants.addEventListener("mouseleave", function () {
    cameraMoveOnPlants.style.background = "transparent";
  });
  cameraMoveOnNewspaper.addEventListener("mouseover", function () {
    cameraMoveOnNewspaper.style.background =
      "radial-gradient(circle,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.3) 30%,rgba(255, 255, 255, 0) 55%)";
    cameraMoveOnNewspaper.style.filter = "contrast(100%)";
  });
  cameraMoveOnNewspaper.addEventListener("mouseleave", function () {
    cameraMoveOnNewspaper.style.background = "transparent";
  });
  cameraResetOnStartPosition.addEventListener("mouseover", function () {
    cameraResetOnStartPosition.style.background =
      "radial-gradient(circle,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.3) 30%,rgba(255, 255, 255, 0) 55%)";
    cameraResetOnStartPosition.style.filter = "contrast(100%)";
  });
  cameraResetOnStartPosition.addEventListener("mouseleave", function () {
    cameraResetOnStartPosition.style.background = "transparent";
  });
  cameraMoveOnEngine.addEventListener("mouseover", function () {
    cameraMoveOnEngine.style.background =
      "radial-gradient(circle,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.3) 30%,rgba(255, 255, 255, 0) 55%)";
    cameraMoveOnEngine.style.filter = "contrast(100%)";
  });
  cameraMoveOnEngine.addEventListener("mouseleave", function () {
    cameraMoveOnEngine.style.background = "transparent";
  });
};
